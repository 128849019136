:root {
  --primary-color: #007bff;
  --secondary-color: #282c34;
  --background-color: #f5f5f5;
  --text-color: #333;
  --light-text-color: #888;
}

.app {
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: var(--text-color);
}

.header {
  background-color: #fff;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 80px;
}

.nav {
  display: flex;
}

.nav-link {
  color: var(--text-color);
  text-decoration: none;
  margin-left: 20px;
  font-weight: bold;
}

.nav-link:hover {
  text-decoration: underline;
}

main {
  flex: 1;
  padding: 20px;
}

.footer {
  background-color: var(--secondary-color);
  color: white;
  text-align: center;
  padding: 10px 0;
  margin-top: auto;
}

input,
button {
  font-family: inherit;
}
