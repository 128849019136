.panelists {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .panelist {
    margin: 10px;
    text-align: center;
  }
  
  .panelist-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .panelist h3 {
    margin-top: 10px;
    font-size: 1.2em;
  }  