.about-page {
  padding: 20px;
  color: white;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.about-page h1{
  color: #282c34;
}

.about-content {
  margin-top: 20px;
}

.about-content p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.trailer {
  margin-top: 40px;
}

.trailer iframe {
  max-width: 100%;
  border: none;
}

.team {
  margin-top: 40px;
}

.team h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #282c34;
}