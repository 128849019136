.guest-page ul li{
    list-style: disc;
    border: none;
    text-align: left;
  }

  .guest-page ul li,  .guest-page ol li{
    list-style: disc;
    border: none;
    text-align: left;
  }
  
  .guest-page ul {
    margin: 0.5em 0;
    padding: 0;
  }

  .subscribe-content li{
    display: block;
  }